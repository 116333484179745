.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.next-btn {
  margin-left: 10px;
}

.web-viewer-title {
  margin-bottom: 0px !important;
  color: white;
  height: 1.7em !important;
  text-align: center;
  padding-top: 0.2em !important;
  background-color: #0f6ae1 !important;
}
.user-profile {
  position: relative;
  display: flex;
  align-items: center;
  padding: 25px;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin: 35px 10% 10px;
  background-color: white;
  height: 200px;
  width: 500px;
}

.user-image-container {
  width: 100px; /* Set the desired width */
  height: 125px; /* Set the desired height */
  border: 4px solid #ccc;
  border-radius: 15px; /* Adjust the border radius as needed */
  box-shadow: 0 2px 4px rgba(7, 6, 6, 0.2); /* Add box shadow */
  overflow: hidden;
}

.user-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain image aspect ratio and cover the container */
}

.user-details {
  margin-left: 25px;
  flex: 1;
}

.user-name {
  font-size: 17px;
  margin: 0;
}

.user-position,
.user-email,
.user-phone {
  margin: 5px 0;
}

/* Media query for screens smaller than 1024px (common laptop size) */
@media screen and (max-width: 1024px) {
  .user-profile {
    flex-direction: column;
    width: auto; /* Adjust width to fit smaller screens */
    margin: 20px; /* Adjust margins for smaller screens */
    height: auto; /* Adjust height for a vertical layout */
  }

  .user-image-container {
    width: 80px; /* Smaller image container for smaller screens */
    height: 100px;
  }

  .user-details {
    margin-left: 0;
    margin-top: 15px;
  }

  .user-name {
    font-size: 15px; /* Smaller font size for smaller screens */
  }
}

.tape {
  position: absolute; /* Position it relative to user-profile */
  /*top: -10px; /* Adjust as needed */
  /*right: -10px; /* Adjust as needed */
  background-color: #0f6ae1; /* Your choice of color */
  color: white; /* Text color */
  padding: 5px 10px; /* Adjust padding to your preference */
  /*transform: rotate(45deg);  Rotate the tape */
  font-size: 14px; /* Adjust font size as needed */
  border-radius: 5px; /* Optional: if you want rounded corners */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Optional: for a slight shadow */
  right: 10px;
  bottom: 27px;
}

/* Media query for mobile devices (screens smaller than 768px) */
@media screen and (max-width: 768px) {
  .user-profile {
    flex-direction: column;
    width: auto; /* Adjust width to fit mobile screens */
    margin: 15px; /* Adjust margins for mobile screens */
    padding: 15px; /* Adjust padding for mobile screens */
    height: auto; /* Adjust height for a vertical layout */
  }

  .user-image-container {
    width: 70px; /* Further reduce size for mobile screens */
    height: 90px;
    margin: 0 auto; /* Center the image container */
  }

  .user-details {
    margin-top: 10px;
    text-align: center; /* Center-align the text for mobile view */
  }

  .user-name {
    font-size: 14px; /* Smaller font size for mobile screens */
  }

  .user-position,
  .user-email,
  .user-phone {
    font-size: 12px; /* Smaller font size for additional texts */
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 768px) {
  .webviewer {
    flex: 1;
    margin: 8px;
    -webkit-box-shadow: 1px 1px 10px #999;
    box-shadow: 1px 1px 10px #999;
    height: calc(100vh - 16px);
    overflow-y: auto;
  }

  .web-viewer-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .webviewer {
    height: 45%;
    margin: 8px;
    -webkit-box-shadow: 1px 1px 10px #999;
    box-shadow: 1px 1px 10px #999;
  }

  .web-viewer-container {
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
